import {
  Card,
  Col,
  Row,
  useTable,
  Form,
  Button,
  Image,
} from "@pankod/refine-antd";
import Cookies from "js-cookie";
import { io } from "socket.io-client";
import { KeyboardEventHandler, useContext, useEffect, useState } from "react";
import { CrudFilter } from "@refinedev/core";
import moment from "moment";
import "moment/locale/ru";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";

import { settings } from "../../common/settings";
import { useMediaQuery } from "common/useMediaQuery";
import { TIME_FORMAT } from "common/constants";
import { ColorModeContext } from "contexts/color-mode";

interface IMessage {
  id: string;
  text: string;
  createdAt: string;
  userId: string;
  isAdmin: boolean;
}

export enum EActiveTab {
  Open = "open",
  All = "all",
}

const token = Cookies.get(settings.auth.accessToken);
const socket = io("wss://api.uwu.dev.pyrobyte.ru", {
  autoConnect: true,
  path: "/support/socket.io",
  auth: { token },
  transports: ["websocket"],
});

export const UserSupportList = () => {
  const { mode } = useContext(ColorModeContext);
  const [form] = Form.useForm();
  const [messageSupport, setMessageSupport] = useState("");
  const [userName, setUserName] = useState("");
  const [chatId, setChatId] = useState("");
  const [avatar, setAvatar] = useState<any>("");
  const [roomId, setRoomId] = useState("");
  const [page, setPage] = useState(2);
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [hasMore, setHasMore] = useState(true);
  let isDesktop = useMediaQuery(1925);

  const [activeTab, setActiveTab] = useState<EActiveTab>(EActiveTab.Open);

  const { tableProps, searchFormProps, tableQueryResult } = useTable({
    syncWithLocation: true,
    hasPagination: true,
    initialPageSize: 20,
    dataProviderName: "supportMessageProvider",
    resource: "user-support",
    errorNotification: {
      type: "error",
      description: "Ошибка",
      message: "Не удалось получить список чатов с пользователями",
    },
    onSearch: (params) => {
      const filters: CrudFilter[] = [];
      const { status } = params as any;
      filters.push({
        field: "status",
        operator: "eq",
        value: status,
      });
      return filters;
    },
  });

  const darkThemeStyle = mode === "dark" ? "#282828" : "#f5f5f5";

  useEffect(() => {
    searchFormProps.form?.setFieldValue("status", "OPEN");
    searchFormProps.form?.submit();
  }, []);

  useEffect(() => {
    axios
      .get(`${settings.api.url}/users/user-support/${roomId}`)
      .then((res) => setMessages(res.data.data))
      .catch((err) => console.log(err));
  }, [roomId]);

  const fetchMoreData = () => {
    axios
      .get(`${settings.api.url}/users/user-support/${roomId}?page=${page}`)
      .then((res) => {
        setMessages((prevItems) => [...prevItems, ...res.data.data]);

        res.data.data.length ? setHasMore(true) : setHasMore(false);
      })
      .catch((err) => console.log(err));

    setPage((prevPage) => prevPage + 1);
  };

  const onResetTextArea = () => {
    setMessageSupport("");
    form.resetFields();
  };

  const joinChat = (id: string) => {
    socket.emit("joinChat", { roomId: id });
    setRoomId(id);
  };

  const leaveChat = (id: string) => {
    socket.emit("leaveChat", { roomId: id });
  };

  const changeRoom = (
    from: string,
    to: string,
    user: string,
    chatId: string,
    avatar: string
  ) => {
    if (from === to) {
      return false;
    }
    if (!roomId) {
      joinChat(to);
    } else {
      leaveChat(from);
      if (to) {
        joinChat(to);
      }
    }
    setUserName(user);
    setChatId(chatId);
    setAvatar(avatar);
    readChatMessages(chatId);
    onResetTextArea();
  };

  let input = document.getElementById("inputFocus");

  function onStayFocusedInput(elem: Node) {
    let sel = window.getSelection();
    sel?.selectAllChildren(elem);
    sel?.collapseToEnd();
  }

  const sendMessageSupport = () => {
    if (!messageSupport.trim().length) {
      return false;
    }

    const div = document.getElementById("scrollableDiv");
    if (div) {
      div.scrollTo({ top: div.scrollHeight, behavior: "instant" });
    }

    socket?.emit("sendMessage", {
      roomId: roomId,
      message: messageSupport,
    });

    onResetTextArea();
    tableQueryResult.refetch();
  };

  useEffect(() => {
    socket.on("newMessage", (newMessage) => {
      setMessages((prevItems) => [
        { ...newMessage, isAdmin: newMessage.isAdmin },
        ...prevItems,
      ]);
    });
  }, [socket]);

  useEffect(() => {
    input && onStayFocusedInput(input);
  }, [input]);

  const closeChat = () => {
    axios
      .get(`${settings.api.url}/users/user-support/chat/close/${chatId}`)
      .then(() => {
        tableQueryResult.refetch();
      })
      .catch((err) => console.log(err));
  };

  const readChatMessages = (chatId: string) => {
    axios
      .post(`${settings.api.url}/users/user-support/chat/read/${chatId}`)
      .then(() => {
        tableQueryResult.refetch();
      })
      .catch((err) => console.log(err));
  };

  const showAllRequests = () => {
    searchFormProps.form?.resetFields();
    searchFormProps.form?.submit();
    setActiveTab(EActiveTab.All);
  };

  const showOpenedRequests = () => {
    searchFormProps.form?.setFieldValue("status", "OPEN");
    searchFormProps.form?.submit();
    setActiveTab(EActiveTab.Open);
  };

  let textarea = document.getElementById("editor");

  textarea?.addEventListener("input", function (e) {
    setMessageSupport((e.target as HTMLElement).innerText);
  });

  textarea?.removeEventListener("input", function (e) {
    setMessageSupport((e.target as HTMLElement).innerText);
  });

  const onKeyDownEnter: KeyboardEventHandler<HTMLDivElement> = (event) => {
    event.code === "Enter" && sendMessageSupport();
  };

  return (
    <Row
      gutter={[16, 16]}
      style={{ display: "flex", justifyContent: "center" }}
    >
      <Col lg={isDesktop ? 12 : 18} xs={isDesktop ? 12 : 18}>
        <Card title="Чаты">
          <div style={{ display: "flex" }}>
            <div
              style={{
                width: "471px",
                backgroundColor: darkThemeStyle,
              }}
            >
              <Form
                layout="vertical"
                {...searchFormProps}
                className="chat-filters"
                style={{
                  backgroundColor: darkThemeStyle,
                }}
              >
                <Form.Item name="status">
                  <Button
                    onClick={showOpenedRequests}
                    htmlType="submit"
                    className={
                      activeTab === EActiveTab.Open
                        ? "chats-button-opened"
                        : "chats-button-all"
                    }
                    style={{
                      backgroundColor:
                        mode === "dark"
                          ? activeTab === EActiveTab.Open
                            ? "#282828"
                            : "#141414"
                          : "#ffffff",
                    }}
                  >
                    Открытые
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    htmlType="button"
                    onClick={showAllRequests}
                    className={
                      activeTab === EActiveTab.All
                        ? "chats-button-opened"
                        : "chats-button-all"
                    }
                    style={{
                      backgroundColor:
                        mode === "dark"
                          ? activeTab === EActiveTab.All
                            ? "#282828"
                            : "#141414"
                          : "#ffffff",
                    }}
                  >
                    Все
                  </Button>
                </Form.Item>
              </Form>

              <div className="chats-users">
                {tableProps.dataSource?.length ? (
                  tableProps.dataSource?.map((item) => (
                    <div
                      className="chats-user"
                      key={item.id}
                      style={{
                        backgroundColor:
                          roomId === item.roomId
                            ? mode === "dark"
                              ? "#141414"
                              : "#fff"
                            : darkThemeStyle,
                      }}
                      onClick={() =>
                        changeRoom(
                          roomId,
                          item.roomId,
                          item.user.nickname,
                          String(item.id),
                          item.user.avatar
                        )
                      }
                    >
                      <div className="chats-user-block">
                        <Image
                          width={58}
                          height={48}
                          className="chats-user-avatar"
                          preview={false}
                          src={
                            Number(item.user.avatar)
                              ? `../images/avatars/avatar${item.user.avatar}.png`
                              : item.user.avatar?.url
                          }
                        />
                        <div className="chats-user-top">
                          <div className="chats-user-header">
                            <div className="chats-user-nickname">
                              {item.user.nickname}
                            </div>
                            <div className="chats-user-time">
                              {moment(item.lastMessage.createdAt).calendar(
                                null,
                                {
                                  sameDay: TIME_FORMAT,
                                  nextDay: "[Вчера]",
                                  lastWeek: "DD.MM.YY",
                                }
                              )}
                            </div>
                          </div>
                          <div className="chats-user-header">
                            <div className="chats-user-message">
                              {item.status === "CLOSED"
                                ? "Закрыт"
                                : !item.isReading && !item.lastMessage.isAdmin
                                ? "Новое сообщение"
                                : item.lastMessage.text}
                            </div>
                            {!item.isReading && !item.lastMessage.isAdmin ? (
                              <span className="chats-user-message-unread"></span>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p
                    style={{
                      textAlign: "center",
                      marginTop: "20px",
                      fontSize: "18px",
                    }}
                  >
                    Загрузка чатов...
                  </p>
                )}
              </div>
            </div>
            <div style={{ width: "775px" }}>
              {roomId ? (
                <div>
                  <div
                    className="chat-header"
                    style={{
                      backgroundColor: darkThemeStyle,
                    }}
                  >
                    <div className="chat-header-info">
                      <Image
                        width={40}
                        height={40}
                        className="chats-user-avatar"
                        preview={false}
                        src={
                          Number(avatar)
                            ? `../images/avatars/avatar${avatar}.png`
                            : avatar?.url
                        }
                      />
                      {userName}
                    </div>
                    <Button onClick={closeChat} type="primary">
                      Закрыть вопрос
                    </Button>
                  </div>
                  <div id="scrollableDiv" className="chat-scroll">
                    <InfiniteScroll
                      dataLength={messages.length}
                      next={fetchMoreData}
                      style={{
                        display: "flex",
                        flexDirection: "column-reverse",
                        overflow: "hidden",
                      }}
                      inverse={true}
                      hasMore={hasMore}
                      loader={
                        !messages.length ? (
                          <h4 style={{ textAlign: "center" }}>Загрузка...</h4>
                        ) : null
                      }
                      scrollableTarget="scrollableDiv"
                    >
                      <div className="chat-inner">
                        {messages
                          .map((msg) => (
                            <div
                              key={msg.id}
                              style={{
                                justifyContent: msg.isAdmin
                                  ? "flex-end"
                                  : "flex-start",
                              }}
                              className="chat-messages"
                            >
                              <div
                                className="chat-message"
                                style={{
                                  backgroundColor: msg.isAdmin
                                    ? darkThemeStyle
                                    : "#1568DB",
                                  color: msg.isAdmin
                                    ? mode === "dark"
                                      ? "#fff"
                                      : "#282828"
                                    : mode === "dark"
                                    ? "#fff"
                                    : "#fff",
                                }}
                              >
                                <div style={{ fontSize: "16px" }}>
                                  {msg.text}
                                </div>
                                <div
                                  style={{
                                    fontSize: "12px",
                                    textAlign: "right",
                                  }}
                                >
                                  {moment(msg.createdAt).format(TIME_FORMAT)}
                                </div>
                              </div>
                            </div>
                          ))
                          .reverse()}
                      </div>
                    </InfiniteScroll>
                  </div>
                  <div>
                    <Form form={form} className="chat-form">
                      <Form.Item name="comment">
                        <div
                          id="editor"
                          className="chat-container"
                          onKeyDown={onKeyDownEnter}
                        >
                          <p
                            contentEditable="true"
                            data-ph="Написать сообщение..."
                            id="inputFocus"
                            style={{
                              backgroundColor: darkThemeStyle,
                            }}
                          ></p>
                          <img
                            src="../images/sendMessageIcon.svg"
                            width={24}
                            height={24}
                            className="chat-container-icon"
                            onClick={sendMessageSupport}
                            style={{
                              filter: mode === "dark" ? "invert(1)" : "none",
                            }}
                          />
                        </div>
                      </Form.Item>
                    </Form>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </Card>
      </Col>
    </Row>
  );
};
